import 'atropos/atropos.css';
import Atropos from 'atropos/atropos';
import {router} from './router';

const greetings = [
  'Hey',
  '你好',
  'Salut',
  'Hallo',
  'Shalom',
  'Ciào',
  '안녕',
  'Olá',
  'Hej',
  'Xin chào'
];

const pageLogo = document.getElementById('page-logo');
const greeting = document.getElementById('intro-greeting');
const homePage = document.getElementById('home-page');

class App {
  init() {
    this.setupPage();
    this.router = router.init();
    document.body.classList.remove('preload');
  }
  
  setupPage() {
    this.setGreeting();
    this.addAtroposCards();
    this.bindLogoClick();
  }
  
  setGreeting() {
    const randGreeting = greetings[Math.floor(Math.random() * greetings.length)];
    greeting.innerHTML = randGreeting;
  }

  addAtroposCards() {
    document.querySelectorAll('.atropos-card').forEach(el => {
      Atropos({
        el,
        rotateTouch: 'scroll-y'
      });
    })
  }

  bindLogoClick() {
    pageLogo.addEventListener('click', e => {
      e.preventDefault();

      homePage.scrollTo({ top: 0, behavior: 'smooth'})
    });
  }
};

export const app = new App();
